<template>
    <el-main>
        <el-button type="primary" size="small" class="add_goods" @click="dialogVisible = true">
            + 添加分类
        </el-button>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="菜品分类：">
                <el-input v-model="dataForm.classify_name" size="small" placeholder="请输入菜品名称"></el-input>
            </el-form-item>
            <el-form-item label="是否必选品：">
                <el-select v-model="dataForm.is_required" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="-1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="15px">
                <el-button type="primary" size="small" @click="get_data_list(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="data_list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="sort" label="排序" align="center" width="200">
                <template #header>
                    <div class="edit">
                        <span>排序</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input type="number" :min="0" size="small" v-model="scope.row.sort"
                        @change="editSort(scope.row, 1)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="分类名称" align="center" prop="classify_name"></el-table-column>
            <el-table-column label="是否必选品" align="center">
                <template v-slot="{ row }">
                    <el-switch v-model="row.is_required" :active-value="1" :inactive-value="-1"
                        @change="editIsRequired(row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                    <el-button type="text" @click="deleteGoods(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="dataForm.page" :pageNum="dataForm.rows" @updatePageNum="updateData"></Paging>
        <el-dialog :title="form.id ? '编辑分类' : '添加分类'" :visible.sync="dialogVisible" width="40%" @closed="handleClose">
            <el-form label-width="100px" :rules="rules" ref="form" :model="form">
                <el-form-item label="分类名称：" prop="classify_name">
                    <el-input v-model="form.classify_name" size="small"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input type="number" :min="0" size="small" v-model="form.sort"></el-input>
                </el-form-item>
                <!--<el-form-item label="是否必选品">
                    <el-switch v-model="form.is_required" :active-value="1" :inactive-value="-1"></el-switch>
                    <div class="tips">必选品仅支持一个，表示用户点餐必须选择，才可以提交订单</div>
                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmType">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>
  
<script>
import { getDateformat } from '@/util/getDate.js';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
    components: {
        Paging,
    },
    created () {
        this.get_data_list();
    },
    data () {
        return {
            getDateformat,
            // 列表查询条件
            dataForm: {
                page: 1,
                rows: 10,
                is_required: '',
                classify_name: ''
            },

            data_list: [],
            total_number: 0,
            dialogVisible: false,

            form: {
                classify_name: '',
                sort: 99,
                //is_required: -1
            },
            rules: {
                classify_name: [{ required: true, message: "请填写分类名称", trigger: "blur" }],
                sort: [{ required: true, message: "请填写排序", trigger: "blur" }],
            }
        };
    },
    methods: {
        updateData (val, status) {
            if (status == 0) {
                this.dataForm.rows = val;
            } else {
                this.dataForm.page = val;
            }
            this.get_data_list();
        },
        clearSearch () {
            this.dataForm = {
                page: 1,
                rows: 10,
                is_required: '',
                classify_name: ''
            }
            this.get_data_list(1);
        },
        get_data_list (style) {
            if (style) this.dataForm.page = 1;
            this.$axios.post(this.$api.catering.GoodsClassifyList, this.dataForm).then(res => {
                if (res.code === 0) {
                    this.data_list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        confirmType () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let obj = { ...this.form }
                    let url = obj.id ? this.$api.catering.GoodsClassifyEdit : this.$api.catering.GoodsClassifyAdd
                    this.$axios.post(url, obj).then(res => {
                        if (res.code === 0) {
                            this.$message.success(obj.id ? '编辑成功' : `添加成功`);
                            this.dialogVisible = false
                            this.get_data_list(1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            })
        },
        editIsRequired (row) {
            this.$axios.post(this.$api.catering.GoodsClassifyEditIsRequired, {
                id: row.id,
                is_required: row.is_required
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success(`编辑成功`);
                    this.get_data_list(1);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleEdit (row) {
            this.form = {
                id: row.id,
                classify_name: row.classify_name,
                sort: row.sort,
            }
            this.dialogVisible = true
        },
        // 点击删除商品
        deleteGoods (data) {
            this.$confirm('此操作将删除该分类, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return this.$axios.post(this.$api.catering.GoodsClassifyDel, {
                        id: data.id,
                    });
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success(`删除成功`);
                        this.get_data_list(1);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(() => { });
        },
        editSort (row) {
            this.$axios.post(this.$api.catering.GoodsClassifyEditSort, {
                id: row.id,
                sort: row.sort
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success(`编辑成功`);
                    this.get_data_list(1);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleClose () {
            this.form = {
                classify_name: '',
                sort: 99,
            }
            this.$refs.form.clearValidate()
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;

    .add_goods {
        margin: 16px 0;
    }

    .tips {
        color: #999;
    }
}
</style>